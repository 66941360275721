import { _post } from "../helpers";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://server-snowball.herokuapp.com"
    : "http://127.0.0.1:8080";

export const addDocToCollection = async (collectionName, dataObject) => {
  const request = await fetch(`${API_URL}/addDataToCollection`, {
    method: "POST",
    body: JSON.stringify({
      collection: collectionName,
      data: dataObject,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  return data;
};

export const updateCollectionDoc = async (
  collectionName,
  docId,
  dataObject
) => {
  const request = await fetch(`${API_URL}/updateCollectionDoc`, {
    method: "POST",
    body: JSON.stringify({
      collection: collectionName,
      docId,
      data: dataObject,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  return data;
};

export const updateSubCollectionDoc = async (
  collectionName,
  docId,
  subCollectionName,
  subCollectionDocId,
  dataObject
) => {
  try {
    console.log("Firestore 46 | updating subcollection", subCollectionName);
    const request = await fetch(`${API_URL}/updateSubCollectionDoc`, {
      method: "POST",
      body: JSON.stringify({
        collection: collectionName,
        docId,
        subCollection: subCollectionName,
        subCollectionDocId,
        data: dataObject,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await request.json();
    return data;
  } catch (error) {
    console.log("Firestore 62 | issue updating subcollection", error);
  }
};
export const getSubCollectionDoc = async (
  collectionName,
  docId,
  subCollectionName,
  subCollectionDocId
) => {
  try {
    console.log("Firestore 46 | getting subcollection", subCollectionName);
    const request = await fetch(`${API_URL}/getSubcollectionDoc`, {
      method: "POST",
      body: JSON.stringify({
        collection: collectionName,
        docId,
        subCollection: subCollectionName,
        subCollectionDocId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await request.json();
    return data;
  } catch (error) {
    console.log("Firestore 62 | issue updating subcollection", error);
  }
};

export const getCollection = async (collectionName) => {
  console.log("Firestore 40 | getting collection", collectionName);
  try {
    const request = await fetch(`${API_URL}/getCollection`, {
      method: "POST",
      body: JSON.stringify({
        collectionName,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await request.json();
    return data;
  } catch (error) {
    console.log("Firestore 34 | error getting collection");
    throw new Error("cannot get collection", error);
  }
};

export const getDocFromCollection = async (collection, documentId) => {
  // console.log("Firestore 60 | getting doc from collection");
  const request = await fetch(`${API_URL}/getDocumentFromCollection`, {
    method: "POST",
    body: JSON.stringify({
      collection,
      documentId,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  return data;
};

export const getSubCollection = async (collection, docId, subCollection) => {
  console.log("Firestore 76 | requesting subcollection ⬇️⬇️⬇️", subCollection);
  const request = await fetch(`${API_URL}/getSubCollection`, {
    method: "POST",
    body: JSON.stringify({
      collection,
      docId,
      subCollection,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  return data;
};

export const pushTosubCollection = async (
  collection,
  docId,
  subCollection,
  data
) => {
  console.log("Firestore 98 | pushing to subcollection");
  const request = await fetch(`${API_URL}/pushToSubCollection`, {
    method: "POST",
    body: JSON.stringify({
      collection,
      docId,
      subCollection,
      data,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await request.json();
  return result;
};

export const pushUserToClassroom = async (
  collection,
  docId,
  subCollection,
  userId,
  data
) => {
  const request = await fetch(`${API_URL}/pushUserToClassroom`, {
    method: "POST",
    body: JSON.stringify({
      collection,
      docId,
      subCollection,
      userId,
      data,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await request.json();
  return result;
};

export const filterDocsFromCollection = async (
  collection,
  fieldId,
  comparison,
  fieldValue
) => {
  // console.log("Firestore 145 | filtering docs from collection");
  const request = await fetch(`${API_URL}/filterCollectionByField`, {
    method: "POST",
    body: JSON.stringify({
      collection,
      fieldId,
      comparison,
      fieldValue,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  return data;
};

export const deleteDocFromCollection = async (collection, docId) => {
  const request = await fetch(`${API_URL}/deleteDocFromCollection`, {
    method: "POST",
    body: JSON.stringify({
      collection,
      docId,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  return data;
};

export const filterFieldPath = async (collection, idsArray) => {
  console.log("Firestore 178 | filtering field path");
  const request = await fetch(`${API_URL}/filterFieldPath`, {
    method: "POST",
    body: JSON.stringify({
      collection,
      idsArray,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await request.json();
  return data;
};

export const arrayContainsInCollection = async (
  collection,
  property,
  value
) => {
  try {
    const data = await _post("arrayContains", {
      collection,
      property,
      value,
    });
    console.log("Firestore 257 | array contains data", data);
    return data;
  } catch (error) {
    console.log("Firestore 259 | error with request", error);
  }
};
