export const questions = [
  {
    category: "budgeting",
    options: [
      { option: "yes", correct: true },
      { option: "no", correct: false },
    ],
    question: "I know how to create and track a budget month to month.",
  },
  {
    category: "budgeting",
    options: [
      { option: "yes", correct: true },
      { option: "no", correct: false },
    ],
    question:
      "I understand the difference between fixed and variable expenses.",
  },
  {
    category: "budgeting",
    options: [
      { option: "yes", correct: true },
      { option: "no", correct: false },
    ],
    question:
      "If I have a financial emergency of $1,000, I can afford it from my own savings without going broke or borrowing money.",
  },
  {
    category: "investing",
    options: [
      { option: "$ 1,100", correct: false },
      { option: "$ 1,628", correct: true },
      { option: "$ 1,500", correct: false },
      { option: "No clue", correct: false },
    ],
    question:
      "If I invest $1000 and it returns 5% over the course of ten years, at the end of the 10 years I'll have",
  },
  {
    category: "debt",
    options: [
      { option: "5 years", correct: false },
      { option: "3.5 years", correct: true },
      { option: "2.7 years", correct: false },
      { option: "No clue", correct: false },
    ],
    question:
      "If you have a $1000 loan with a 20% interest annualy, how long would it take, approximately, for the loan to double if you don't pay it off?",
  },
  {
    category: "debt",
    options: [
      { option: "yes", correct: true },
      { option: "no", correct: false },
    ],
    question: "I know how debt interest works against me",
  },
  // {
  //   category: "budgeting",
  //   options: [
  //     { option: "yes", correct: true },
  //     { option: "no", correct: false },
  //   ],
  //   question:
  //     "If I buy a house, I understand what is a reasonable amount to pay monthly for my mortgage based on my income.",
  // },
  {
    category: "investing",
    options: [
      { option: "yes", correct: false },
      { option: "no", correct: true },
    ],
    question:
      "Buying a stock in a single company usually provides a safer return than an index fund",
  },
];
