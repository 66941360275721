import React, { useState } from "react";
import "./index.css";

const StudentTable = ({
  classData = null,
  sort,
  lastLogin,
  handleUserNameUpdate,
  sortedByLastFirst,
  setSortedByLastFirst,
  inactiveStudentsFilter,
  setInactiveStudentsFilter,
  activeStudentsFilter,
  setActiveStudentsFilter,
}) => {
  if (!classData) return null;

  return (
    <div className="studenttable__container">
      <Sorting
        classData={classData}
        setInactiveStudentsFilter={setInactiveStudentsFilter}
        sortedByLastFirst={sortedByLastFirst}
        setSortedByLastFirst={setSortedByLastFirst}
        lastLogin={lastLogin}
        sort={sort}
        inactiveStudentsFilter={inactiveStudentsFilter}
        activeStudentsFilter={activeStudentsFilter}
        setActiveStudentsFilter={setActiveStudentsFilter}
      />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Last Login</th>
            <th scope="col">Email</th>
            <th scope="col">Currently On</th>
          </tr>
        </thead>
        <tbody>
          {classData &&
            classData.map((student, index) => {
              return (
                <StudentRow
                  key={index}
                  student={student}
                  index={index}
                  handleUserNameUpdate={handleUserNameUpdate}
                  sortedByLastFirst={sortedByLastFirst}
                  inactiveStudentsFilter={inactiveStudentsFilter}
                  activeStudentsFilter={activeStudentsFilter}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const StudentRow = ({
  student,
  index,
  handleUserNameUpdate,
  sortedByLastFirst,
  inactiveStudentsFilter,
  activeStudentsFilter,
}) => {
  const [name, setName] = useState(student.name);

  const correctName = !sortedByLastFirst
    ? student.name
    : student?.name?.split(" ").reverse().join(", ");
  const [editing, setEditing] = useState(false);

  let date = new Date(student.lastLogin).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    day: "2-digit",
    month: "numeric",
  });

  const handleUpdates = (student) => {
    handleUserNameUpdate(student.userId, name);
    setEditing(!editing);
  };

  if (isToday(student.lastLogin) && inactiveStudentsFilter) {
    return null;
  }

  if (!isToday(student.lastLogin) && activeStudentsFilter) {
    return null;
  }

  return (
    <tr
      key={index}
      className="studenttable"
      onClick={() => {
        console.log("StudentTable.js 49 | student ", student);
      }}
    >
      <td className="userfirstlast__container">
        {editing ? (
          <div>
            <input
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
            <button onClick={() => handleUpdates(student)}>Update</button>
          </div>
        ) : (
          <>
            <div>{correctName}</div>

            <button
              className="editname__button"
              onClick={() => {
                setEditing(true);
              }}
            >
              ✎
            </button>
          </>
        )}
      </td>
      <td className="offline__online__student">
        {isToday(student.lastLogin)
          ? `🟢 Online ${date}`
          : `🔴 Offline ${date !== "Invalid Date" ? date : ""}`}
      </td>
      <td>{student.email}</td>
      <td>{student.currentlyOn}</td>
    </tr>
  );
};

const isToday = (date) => {
  const today = new Date();

  let lastDate = new Date(date);
  return (
    lastDate.getDate() == today.getDate() &&
    lastDate.getMonth() == today.getMonth() &&
    lastDate.getFullYear() == today.getFullYear()
  );
};

const Sorting = ({
  classData,
  setInactiveStudentsFilter,
  sortedByLastFirst,
  setSortedByLastFirst,
  lastLogin,
  sort,
  inactiveStudentsFilter,
  activeStudentsFilter,
  setActiveStudentsFilter,
}) => {
  const handleActiveFilter = () => {
    setInactiveStudentsFilter(false);
    setActiveStudentsFilter(!activeStudentsFilter);
  };

  const handleInactiveFilter = () => {
    setActiveStudentsFilter(false);
    setInactiveStudentsFilter(!inactiveStudentsFilter);
  };

  return (
    <>
      <button
        onClick={() => {
          console.log("StudentTable.js 29 | setting sorted", sortedByLastFirst);
          setSortedByLastFirst(!sortedByLastFirst);
        }}
        className={`btn btn${
          !sortedByLastFirst ? "-outline" : ""
        }-success btn-sm mr-1 mb-1`}
      >
        {!sortedByLastFirst ? "Last, First" : "First, Last"}
      </button>
      <button
        onClick={sort}
        className="btn btn-outline-success btn-sm mr-1 mb-1"
      >
        Sort
      </button>
      <button
        onClick={lastLogin}
        className="btn btn-outline-success btn-sm mr-1 mb-1"
      >
        Last Login
      </button>
      <button
        onClick={() => {
          handleActiveFilter();
        }}
        className={`btn btn${
          !activeStudentsFilter ? "-outline" : ""
        }-success btn-sm mr-1 mb-1`}
      >
        Active Today (
        {classData.filter((student) => isToday(student.lastLogin)).length})
      </button>
      <button
        onClick={() => {
          handleInactiveFilter();
        }}
        className={`btn btn${
          !inactiveStudentsFilter ? "-outline" : ""
        }-danger btn-sm mr-1 mb-1`}
      >
        Inactive Today (
        {classData.filter((student) => !isToday(student.lastLogin)).length})
      </button>
    </>
  );
};

export default StudentTable;
