import React from "react";
import "./index.css";

export const LoginButton = ({ isLoggedIn, startAuth }) => {
  if (isLoggedIn) return null;
  return (
    <div onClick={() => startAuth()} className="loginbutton__container">
      Login
    </div>
  );
};
