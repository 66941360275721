import React from "react";

import Modal from "antd/es/modal";

import { addDocToCollection } from "../../../../api/Firestore";
import Input from "../../../shared/Input";
import Button from "../../../shared/Button";

class CreateLessonModal extends React.Component {
  state = { visible: false, name: null, learningObjectives: null };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (e) => {
    console.log(e);
    const { name, learningObjectives } = this.state;

    return addDocToCollection("lessons", { name, learningObjectives })
      .then(() => {
        console.log("CreateLessonModal.js 19 | lesson created!");
        this.setState({
          visible: false,
        });
      })
      .catch((error) => {
        console.log(
          "CreateLessonModal.js 22 | error creating lesson",
          "ERROR",
          error
        );
      });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  enabledButton() {
    const { name, learningObjectives } = this.state;
    return name && learningObjectives;
  }

  render() {
    return (
      <>
        <Button title="Create Lesson" type="primary" onClick={this.showModal} />

        <Modal
          title="Create Lesson"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Input
            name="name"
            onChange={(e) => this.handleChange(e)}
            placeholder="Lesson Name"
          />
          <Input
            name="learningObjectives"
            onChange={(e) => this.handleChange(e)}
            placeholder="Learning Goals"
          />
          <Input
            name="lessonOrder"
            onChange={(e) => this.handleChange(e)}
            placeholder="Lesson Order"
          />
        </Modal>
      </>
    );
  }
}

export default CreateLessonModal;
