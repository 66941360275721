import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";
import { LoginButton } from "../LoginButton";
import Icon from "../shared/Icon";
import { useDispatch, useSelector } from "react-redux";
import NavigationLink from "./NavigationLink";
import NavigationHeader from "./NavigationHeader";

const Navigation = (props) => {
  const [mobileNavActive, toggleNavbar] = useState(false);
  const { currentLocation } = useSelector((state) => state.location);
  console.log("Navigation.js 12 | location", currentLocation);
  const dispatch = useDispatch();

  const logout = props.token ? (
    <div onClick={props.logOut} href="" className="logoutbutton__container">
      <Icon name="logout" width={20} height={20} />
      <p className="nav-text">Logout</p>
    </div>
  ) : null;

  const dashBoard =
    props.isTeacher == true ? (
      <Link to="/dashboard">
        <i
          onClick={() => toggleNavbar(!mobileNavActive)}
          className="fa fa-tachometer"
          aria-hidden="true"
        ></i>
        <p className="nav-text">Dashboard</p>
      </Link>
    ) : null;

  const adminDashboard =
    props.userData && props.userData.isAdmin == true ? (
      <Link to="/admin">
        <p className="nav-text">Admin</p>
      </Link>
    ) : null;

  const userHandler =
    props.userData && props.userData.isAdmin == true ? (
      <Link to="/userHandler">
        <p className="nav-text">Users</p>
      </Link>
    ) : null;

  const feedbackLink = () => {
    return (
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSc7R3I7EcC0kygtU-BkUSiFX0J5X8GExLHyTmCU8ATdBCXnQA/viewform"
        target="_blank"
        className="btn  btn-sm"
      >
        <p className="nav-text">Feedback</p>
      </a>
    );
  };
  const navigation = [
    {
      to: "/",
      name: "Home",
      icon: "home",
      auth: false,
    },
    { to: "/profile", name: "Profile", icon: "profile", auth: true },
    { to: "/aboutUs", name: "About Us", icon: "learning", auth: false },
    { to: "/classrooms", name: "Classrooms", icon: "learning", auth: true },
    { to: "/files", name: "Spreadsheets", icon: "files", auth: true },
  ];

  return (
    <div>
      <div className={`sidenav`}>
        {console.log("Navigation.js 78 | window location", props.pathname)}
        <NavigationHeader />
        {navigation &&
          navigation.map((link, index) => {
            const auth = props.token ? true : false;
            console.log("Navigation.js | 75", link.name, link.to, link.icon);
            if (link.auth === true && !auth) return null;
            return (
              <NavigationLink
                index={index}
                currentLocation={currentLocation}
                dispatch={dispatch}
                link={link}
              />
            );
          })}
        <LoginButton
          startAuth={props.startAuth}
          isLoggedIn={props.token ? true : false}
        />
        {dashBoard}
        {adminDashboard}
        {userHandler}
        {logout}
        {/* <Timer logOut={props.logOut} token={props.token} /> */}
        {/* {feedbackLink()} */}
        <div style={{ height: 200 }}></div>
      </div>
    </div>
  );
};

export default Navigation;
