const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://server-snowball.herokuapp.com";

export const _updateUserProperties = async (userId, objectToUpdate) => {
  console.log("App.js 202 | updating user", userId);
  const url = `${API_URL}/updateUserInfo`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      googleId: userId,
      objectToUpdate,
    }),
  })
    .then(() => {
      console.log("user.js | 17", " updated user ");
    })
    .catch((error) => {
      console.log(" | 17", "error updating user");
    });
};

export const _paidUserTrue = async (googleId) => {
  console.log("App.js 202 | setting paid user", googleId);
  const url = `${API_URL}/updatePaidUser`;
  try {
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        googleId,
        paid: true,
      }),
    });
    console.log("App.js 198 | request successful");
    return request;
  } catch (error) {
    console.log("App.js 199 | error with request", error);
    return error;
  }
};

export const _paidUserFalse = async (apiUrl, googleId) => {
  console.log("App.js 202 | setting paid user", googleId);
  const url = `${apiUrl}`;
  try {
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        googleId,
        paid: false,
      }),
    });
    console.log("App.js 198 | request successful", request);
    return request;
  } catch (error) {
    console.log("App.js 199 | error with request", error);
  }
};
