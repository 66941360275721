import { _post } from "../helpers";

export const _getUsersSignedUpInDateRange = async (days) => {
  try {
    const data = await _post("findUsersBetweenDates", { days });
    console.log("MongoDB 5 | data", data);
    return data;
  } catch (error) {
    throw new Error("Unable to Post");
  }
};
