import React, { useState, useEffect } from "react";

import LessonWithSectionsProgressContainer from "./components/LessonWithSectionsProgressContainer";
import "./index.css";

const ProgressDashboard = ({
  classData = null,
  lessons,
  sortedByLastFirst,
}) => {
  useEffect(() => {
    console.log(
      "ProgressDashboard 11 | lessons and class data",
      lessons,
      classData
    );
  }, []);
  return (
    <div className="view-class-container">
      <div className="classroom-progress-cols">
        <div className="usersinlesson__container">
          {lessons &&
            Object.entries(lessons)
              .sort((a, b) =>
                a[1].lessonData.orderNumber > b[1].lessonData.orderNumber
                  ? 1
                  : -1
              )
              .map((lesson, index) => {
                return (
                  <LessonWithSectionsProgressContainer
                    classData={classData}
                    lesson={lesson}
                    key={lesson[0]}
                    sortedByLastFirst={sortedByLastFirst}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ProgressDashboard;
