import React, { useEffect, useState } from "react";
import {
  addDocToCollection,
  arrayContainsInCollection,
  deleteDocFromCollection,
  filterDocsFromCollection,
  getCollection,
  getSubCollectionDoc,
  pushUserToClassroom,
  updateCollectionDoc,
  updateSubCollectionDoc,
} from "../../api/Firestore";
import { _updateUserProperties } from "../../api/user";
import StudentView from "./components/StudentView";
import TeacherView from "./components/TeacherView";
import "./index.css";

const Classrooms = ({ userData, user, _handleUpdateClassId, _setPaidUser }) => {
  const [classrooms, setClassrooms] = useState(null);
  const [classroomName, setClassroomName] = useState(null);
  const [classToJoin, setClassToJoin] = useState(null);
  const [joining, setJoining] = useState(false);
  const [joined, setJoined] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Classrooms 13 | useEffect");
    getClassrooms();
    // getAdditionalClassrooms();
  }, []);

  const getClassrooms = async () => {
    if (userData) {
      try {
        let dbClassrooms = null;
        let additionalClassrooms = null;
        if (userData.isAdmin) {
          console.log("Classrooms 38 | get all classrooms");
          dbClassrooms = await getCollection("classrooms");
        } else {
          dbClassrooms = await filterDocsFromCollection(
            "classrooms",
            "creator",
            "==",
            userData._id
          );
          additionalClassrooms = await arrayContainsInCollection(
            "classrooms",
            "teachers",
            user.email
          );
        }

        console.log("Classrooms 46 | db classrooms", dbClassrooms);
        console.log(
          "Classrooms 53 | additional classrooms",
          additionalClassrooms
        );

        if (dbClassrooms && additionalClassrooms) {
          console.log(
            "Classrooms 59 | should be pushing one into the other",
            dbClassrooms.data.length,
            additionalClassrooms.data.length
          );
          additionalClassrooms.data.forEach((additionalClassroom) => {
            dbClassrooms.data.push(additionalClassroom);
          });
        }

        setClassrooms(dbClassrooms.data);
      } catch (error) {
        console.log("Classrooms 43 | error getting classrooms data");
      }
    }
  };

  const getAdditionalClassrooms = async () => {
    console.log("Classrooms 55 | email", userData);
    if (userData && userData.email) {
      const data = await arrayContainsInCollection(
        "classrooms",
        "teachers",
        user.email
      );
      if (data.data) {
      }
    }
  };

  const createClassroom = async () => {
    return addDocToCollection("classrooms", {
      classroomName,
      creator: userData._id,
    })
      .then(() => {
        console.log("Classrooms 22 | added classroom");
        getClassrooms();
        setClassroomName(null);
      })
      .catch((error) => {
        console.log("Classrooms 27 | ", "ERROR", error);
      });
  };

  const deleteClassroom = async (classroomId) => {
    return deleteDocFromCollection("classrooms", classroomId)
      .then(() => {
        console.log("Classrooms | 48", "deleted lesson");
        getClassrooms();
      })
      .catch((error) => {
        console.log("Classrooms 71 | error deleting");
      });
  };

  const viewClassroom = (classroomId) => {};

  const getUserClassroomData = async () => {
    const { _id, classroomId } = userData;

    // get classroom uid with the name
    const findCurrentClassroomData = await filterDocsFromCollection(
      "classrooms",
      "classroomName",
      "==",
      classroomId
    );

    if (findCurrentClassroomData.data.length > 0) {
      const prevClassId = findCurrentClassroomData.data[0].id;
      console.log("Classrooms 97 | prevclass id", prevClassId);
      const prevClassUserData = await getSubCollectionDoc(
        "classrooms",
        prevClassId,
        "users",
        _id
      );

      console.log(
        "Classrooms 105 | got user lessons from current classroom",
        prevClassUserData?.data?.lessons
      );

      return prevClassUserData?.data?.lessons;
    } else {
      console.log("Classrooms 97 | no prev classroom found");
      return null;
    }
  };

  const joinClassroom = async () => {
    setJoining(true);
    const lessonsFromPrevClassroom = await getUserClassroomData();

    console.log(
      "Classrooms 122 | current user classroom data",
      lessonsFromPrevClassroom
    );

    if (classToJoin === null) return;

    // get current classroom data

    const dbClassrooms = await filterDocsFromCollection(
      "classrooms",
      "classroomName",
      "==",
      classToJoin
    );

    let matchedList = [];

    if (dbClassrooms) {
      dbClassrooms.data.forEach((classroomFound) => {
        matchedList.push({
          data: classroomFound.data,
          id: classroomFound.id,
        });
      });

      if (matchedList[0]) {
        console.log("Classrooms 89 | user data before update", userData);
        return pushUserToClassroom(
          "classrooms",
          matchedList[0].id,
          "users",
          userData._id,
          {
            userId: userData._id,
            name: userData.username,
            email: userData.email,
            lastLogin: Date.now(),
          }
        )
          .then(async () => {
            setJoining(false);
            setJoined(true);
            await _setPaidUser().then(() => {
              _handleUpdateClassId(classToJoin);
            });
            await updateCollectionDoc("userProgress", userData._id, {
              classroomId: classToJoin,
            });

            if (lessonsFromPrevClassroom) {
              await updateSubCollectionDoc(
                "classrooms",
                matchedList[0].id,
                "users",
                userData._id,
                { lessons: lessonsFromPrevClassroom, name: userData.username }
              );
            }
          })
          .catch((error) => {
            console.log("Classrooms 121 | error", error);
            setError(error.message);
          });
      } else {
        console.log("Classrooms 102 | no match");
        setJoining(false);
        setError("No classroom match!");
        setTimeout(() => {
          setError(null);
        }, 2000);
      }
    } else {
      console.log("Classrooms 76 | not found");
    }
  };

  return (
    <div className="classrooms-container container">
      <h1>
        Hi,
        {user && user.name.split(" ")[0]}
      </h1>
      <p>Current Class Id: </p>
      <div className="classid-name">
        {userData && userData.classroomId ? userData.classroomId : "No Class"}
      </div>

      {userData && userData.isTeacher && (
        <TeacherView
          setClassroomName={setClassroomName}
          createClassroom={createClassroom}
          classrooms={classrooms}
          viewClassroom={viewClassroom}
          deleteClassroom={deleteClassroom}
          isAdmin={user.isAdmin}
        />
      )}

      <StudentView
        joinClassroom={joinClassroom}
        setClassToJoin={setClassToJoin}
        joining={joining}
        joined={joined}
        error={error}
      />
    </div>
  );
};

export default Classrooms;
