import React from "react";
import { Link } from "react-router-dom";
import { setLocation } from "../../store/location/locationActions";
import Icon from "../shared/Icon";

const NavigationLink = ({ link, dispatch, index, currentLocation }) => {
  return (
    <Link
      key={index}
      to={link.to}
      className={`sidenav__link ${
        currentLocation === link.to && "sidenavlink__active"
      }`}
      onClick={() => {
        dispatch(setLocation(link.to));
      }}
    >
      <Icon name={link.icon} width={20} height={20} />
      <p className="nav-text">{link.name} </p>
    </Link>
  );
};

export default NavigationLink;
