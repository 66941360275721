import React from "react";
import "./index.css";

const BuiltWith = () => {
  return (
    <>
      <h2 className="brands-title">Built with</h2>
      <div className="brands">
        <img
          className="brand-image mr-4"
          src={"/assets/images/clipboard-image-4.png"}
        />
        <img
          className="brand-image mr-4"
          src={"/assets/images/clipboard-image-1.png"}
        />
        <img
          className="brand-image mr-4"
          src={"/assets/images/clipboard-image-2.png"}
        />
        <img
          className="brand-image mr-4"
          src={"/assets/images/clipboard-image-3.png"}
        />
        <img
          className="brand-image mr-4"
          src={"/assets/images/clipboard-image-5.png"}
        />
      </div>
    </>
  );
};

export default BuiltWith;
