import React from "react";
import "./index.css";
import Icon from "antd/es/icon";

const data = [
  {
    title: "Real Finance Games",
    description:
      "Real Life Simulations that get you ready for complex financial decisions. ",
  },
  {
    title: "Project Based Learning",
    description:
      "You only learn by doing, so get ready to make financial decisions. ",
  },
  {
    title: "Real Tools",
    description:
      "Learn to manage Spreadsheets with secure Google Documents, use them later! ",
  },
];

export const LoginModal = ({ startAuth }) => {
  const handleAuth = () => {
    startAuth();
  };
  return (
    <div className="login-modal">
      <div className="login-modal-container">
        <div className="login-modal-info">
          <h4>Join hundreds of students for FREE learning Real Finance.</h4>
          {data.map(({ title, description }, index) => {
            return (
              <InfoItem key={index} title={title} description={description} />
            );
          })}
        </div>
        <div className="login-modal-login-button">
          <h2 className="font-weight-bold">Sign in to Snowball</h2>
          <p>Get a free account and start playing now!</p>
          <button
            onClick={() => handleAuth()}
            className="sign-in-modal-google font-weight-bold"
          >
            <Icon type="google" style={{ marginRight: 20 }} />
            {"  "} Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ title, description }) => {
  return (
    <div className="login-modal-info-item">
      <h5>
        <Icon type="check" style={{ color: "rgb(11 241 9)" }} /> {title}
      </h5>
      <p>{description}</p>
    </div>
  );
};
