import React from "react";
import { Link } from "react-router-dom";

const About2 = () => {
  return (
    <section>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-lg-6 mb-6 mb-lg-0">
            <img
              src={"/assets/images/sample-lesson-1.png"}
              alt="Image"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-5">
            <div>
              {" "}
              <span className="badge badge-primary-soft p-2">
                <i className="la la-exclamation ic-3x rotation" />
              </span>
              <h2 className="mt-3 font-weight-normal">
                Financial Management from <strong>Zero to Hero</strong>
              </h2>
              <p className="lead font-weight-light">
                We use{" "}
                <span className="text-black font-weight-bold">real data</span>,{" "}
                <span className="text-black font-weight-bold">
                  life simulations
                </span>{" "}
                and
                <span className="text-black font-weight-bold">
                  {" "}
                  spreadsheets{" "}
                </span>
                to teach finance.
              </p>
            </div>
            <div className="d-flex flex-wrap justify-content-start">
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Spreadsheet Management</p>
                </div>
              </div>
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Real Data Management</p>
                </div>
              </div>
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Real Life Simulations</p>
                </div>
              </div>
              <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                <div className="d-flex align-items-center">
                  <div className="badge-primary-soft rounded p-1">
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  </div>
                  <p className="mb-0 ml-3">Data Tracking</p>
                </div>
              </div>
            </div>{" "}
            <Link
              to="/games"
              className="btn mt-5"
              style={{ backgroundColor: "#1c1d1f", color: "white" }}
            >
              Start Now
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About2;
