import React, { useEffect, useState } from "react";
import "./ChooseSimulation.css";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { Helmet } from "react-helmet";

import { simulations } from "./simulatorsData";
import { SnowballProForm } from "../../SnowballProForm";
import ModalBackground from "../../ModalBackground";
import LessonsContainer from "../../LessonsContainer";
import CreateLessonModal from "./components/CreateLessonModal";

import { addDocToCollection } from "../../../api/Firestore";

const ChooseSimulation = ({ paidUser, user, history, classSettings }) => {
  const [notificationDisplayed, setNotificationDisplayed] = useState(false);
  const [showPro, toggleShowPro] = useState(false);

  console.log(
    "ChooseSimulation.js 19 | class settings in choose sim",
    classSettings
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!notificationDisplayed) {
      setNotificationDisplayed(true);
    }
  });

  return (
    <>
      <Helmet>
        <title>Games</title>
        <meta
          name="finance games"
          content="Understand finance with real games and tutorials"
        />
      </Helmet>
      <div className="choose container">
        <div className="sim-menu">
          <div className="sim-header">
            {user?.username ? (
              <h3 className="font-weight-normal">
                Welcome back,{" "}
                <span className="font-weight-bold">{user.username}</span>
              </h3>
            ) : (
              <h3 className="font-weight-bold">
                Welcome to Snowball, start for free!
              </h3>
            )}
            <p className="sim-subtitle">Learn your way to finance.</p>
          </div>
          <LessonsContainer user={user} classSettings={classSettings} />
          {showPro ? (
            <ModalBackground show={showPro}>
              <SnowballProForm />
            </ModalBackground>
          ) : null}
          {user && user.isAdmin && <CreateLessonModal />}
        </div>
      </div>
    </>
  );
};

export default compose(withRouter)(ChooseSimulation);
