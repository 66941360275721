import React from "react";

const StudentView = ({
  setClassToJoin,
  joinClassroom,
  joining,
  joined,
  error,
}) => {
  return (
    <div>
      <h3>Join Classroom</h3>
      <input onChange={(e) => setClassToJoin(e.target.value)} />
      <button onClick={joinClassroom}>{joining ? "Joining..." : "Join"}</button>
      <p>{joined ? "Joined!" : null}</p>
      <p>{error ? error : null}</p>
    </div>
  );
};

export default StudentView;
