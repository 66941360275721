import React from "react";

export const Icon = ({ name = "", width = 30, height = 30, onClick }) => {
  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <img
      onClick={() => handleClick()}
      src={name !== "" ? require(`./svgs/${name}.svg`).default : "home"}
      alt="icon"
      width={width}
      height={height}
    />
  );
};

export default Icon;
