import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

const TeacherView = ({ setClassroomName, createClassroom, classrooms }) => {
  console.log("TeacherView 5 | classrooms", classrooms);
  return (
    <div className="teacherview-container">
      {classrooms ? (
        classrooms.map((classroom, index) => {
          console.log("Classrooms | 8", classroom);
          return (
            <div key={index} className="single-classroom">
              <h4>{classroom.data.classroomName}</h4>

              <Link
                className="btn btn-success"
                to={`/classroom/${classroom.id}`}
                onClick={() => {}}
              >
                View
              </Link>

              {/* <button
                className="btn btn-sm btn-warning"
                style={{ float: "right" }}
                onClick={() => deleteClassroom(classroom.id)}
              >
                Delete
              </button> */}
            </div>
          );
        })
      ) : (
        <div>Loading...</div>
      )}
      <h3>Create Classroom</h3>
      <input onChange={(e) => setClassroomName(e.target.value)} />
      <button onClick={createClassroom}>Create Classroom</button>
    </div>
  );
};

export default TeacherView;
