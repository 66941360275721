import React from "react";
import { useState } from "react";
import QuizQuestions from "./components/QuizQuestions";

import "./index.css";

const InitialAssesment = ({ goToGames }) => {
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  return (
    <div className="quiz-container contianer">
      <h3 className="quiz-title">Do you need Snowball?</h3>
      <p className="quiz-subtitle">Find out with our 7 questions quiz. </p>
      {!started && (
        <button className="quiz-start-button" onClick={() => setStarted(true)}>
          START
        </button>
      )}
      {started && (
        <QuizQuestions
          finished={finished}
          setFinished={setFinished}
          goToGames={goToGames}
        />
      )}
    </div>
  );
};

export default InitialAssesment;
