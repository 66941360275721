import React from "react";

import "./index.css";

const Input = ({ name, value, onChange, placeholder }) => {
  return (
    <input
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      className="shared-input"
    />
  );
};

export default Input;
