import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import "./Landing.css";
import Landing2 from "./Landing2";

const Landing = (props) => {
  return (
    <div className="landing-wrapper">
      <Landing2 startAuth={props.startAuth} />
      <Footer />
    </div>
  );
};

export default Landing;
