import React from "react";
import { Link } from "react-router-dom";

const Hero = ({ start }) => {
  return (
    <section>
      <div className="container">
        <div className="row align-items-center pt-5">
          {/* <ModalVideo
        channel="youtube"
        isOpen={this.state.isOpen}
        autoplay={true}
        videoId="P_wKDMcr1Tg"
        onClose={() => this.setState({ isOpen: false })}
      /> */}

          <div className="col-12 col-lg-5 col-xl-6 order-lg-2 mb-8 mb-lg-0">
            {/* Image */}
            <img
              src={"/assets/images/wallet.png"}
              className="img-fluid"
              alt="..."
            />
          </div>
          <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
            {/* Heading */}
            <h6 className="border rounded-pill px-3 py-2 text-muted d-inline-block">
              {" "}
              <span className="badge badge-pill badge-primary mr-3">New</span>
              Investing Simulator
            </h6>
            <h1 className="display-4 mt-3">
              The <span className="">Ultimate</span>
              <br />
              <span className="text-black font-w-5 font-weight-bold">
                Financial Mastery
              </span>
              <br />
              Platform
            </h1>
            {/* Text */}
            <p className="lead text-muted">
              <strong className="font-weight-bold">Be happier</strong> through
              smarter money management.
            </p>
            {/* Buttons */}{" "}
            <Link
              to="/games"
              style={{ backgroundColor: "#1c1d1f", color: "white" }}
              className="btn shadow mr-1"
            >
              Start Now
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
