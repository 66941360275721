import React, { lazy, Suspense } from "react";
import Title from "./components/Title";
import Details from "./components/Details";
import "./index.css";

const BuyButton = lazy(() => import("./components/BuyButton"));

export const SnowballProForm = ({ _getUserData, _setPaidUser }) => {
  return (
    <div className="snowball-pro-form-container">
      <Title />
      <Details />
      <Suspense fallback={<div>Loading...</div>}>
        <BuyButton _getUserData={_getUserData} _setPaidUser={_setPaidUser} />
      </Suspense>
    </div>
  );
};
