import React, { useEffect, useState } from "react";
import { _getUsersSignedUpInDateRange } from "../../api/MongoDB";

const UserHandler = () => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async (daysBefore = 7) => {
    setLoading(true);
    console.log("UserHandler 14 | getting users", daysBefore, "days before");
    const request = await _getUsersSignedUpInDateRange(daysBefore);
    console.log("UserHandler 8 | request", request);
    setUsers(request.data);
    setLoading(false);
  };

  const updateDaysRange = (days) => {
    getUsers(days);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <button
        className="btn btn-outline-success"
        onClick={() => updateDaysRange(7)}
      >
        Last Week
      </button>
      <button
        className="btn btn-outline-success"
        onClick={() => updateDaysRange(30)}
      >
        Last Month
      </button>
      <button
        className="btn btn-outline-success"
        onClick={() => updateDaysRange(90)}
      >
        Last 3 Months
      </button>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Membership</th>
            <th scope="col">Teacher</th>
            <th scope="col">Email</th>
            <th scope="col">Class ID</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user, index) => {
              console.log("UserHandler | 27", user);
              return (
                <tr key={index}>
                  <th>{index}</th>
                  <th>{user.username}</th>
                  <th>{user.paidUser ? "Pro" : "Free"}</th>
                  <th>{user.isTeacher ? "Teacher" : "Student"}</th>
                  <th>{user.email}</th>
                  <th>{user.classroomId}</th>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default UserHandler;
