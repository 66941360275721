import React from "react";
import AdminTable from "./adminComponents/AdminTable";
import "./index.css";

class AdminView extends React.Component {
  state = {
    allUsersData: null,
  };

  async componentDidMount() {
    this.getInitialData();
  }

  getInitialData = async () => {
    console.log("Mounting admin view");
    if (this.props.userData && this.props.userData.isAdmin) {
      const request = await fetch(`${this.props.API_URL}/getStudentData`);
      const data = await request.json();
      this.setState({
        allUsersData: data,
      });
    } else {
      console.log("No user or not authorized");
    }
  };

  getRegisteredEmailsNumber = () => {};

  render() {
    if (this.props.userData && this.props.userData.isAdmin) {
      return (
        <div className="container admin-container">
          <AdminTable users={this.state.allUsersData} />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default AdminView;
