import React, { useState } from "react";
import { addDocToCollection } from "../../../../api/Firestore";
import "./index.css";

const Newsletter = () => {
  const [email, setEmail] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const handleSubscriptionSubmit = async () => {
    setSubmitting(true);

    return addDocToCollection("registeredEmails", {
      email,
      dateRegistered: Date.now(),
    })
      .then(() => {
        console.log("Newsletter.js 9 | successfully submitted email");
        setSubmitted(true);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        console.log("Newsletter.js 13 | error submitting email");
      });
  };

  return (
    <div className="subscribe-form bg-warning-soft p-5 rounded newsletter__container">
      <h5 className="mb-4 text-white">Interested in what we do?</h5>
      <h6 className="text-light">Subscribe to monthly growth updates</h6>
      <div id="mc-form" className="group">
        <input
          type="email"
          name="EMAIL"
          className="email form-control"
          id="mc-email"
          placeholder="Email Address"
          required
          onChange={(e) => setEmail(e.target.value)}
          style={{ height: "60px" }}
        />
        <button
          className="btn btn-outline-light btn-block mt-3 mb-2"
          name="subscribe"
          value={
            submitted ? "Submitted!" : submitting ? "Submitting" : "Submit"
          }
          onClick={handleSubscriptionSubmit}
        >
          {submitting
            ? "Submitting... "
            : submitted
            ? "Registered!"
            : "Register"}
        </button>
      </div>{" "}
      <small className="text-light">
        Monthly updates with new features, key concepts, and offers!
      </small>
    </div>
  );
};

export default Newsletter;
