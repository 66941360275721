import React from "react";
import "./index.css";

export const AdminTable = ({ users }) => {
  let teachers;
  // const teachers = users.filter(user)
  if (users) {
    console.log("Users data in admin table", users);
    teachers = users
      .filter((user) => user.isTeacher === true)
      .filter((teacher) => teacher.classroomId !== "000");
    console.log("Teachers", teachers);
  }

  if (users) {
    return (
      <>
        <h3>Total Users: {users.length}</h3>
        <h3>
          Paid Users:
          {
            users.filter(
              (user) =>
                user.paidUser === true &&
                user.username !== "Nicolas Aguirre" &&
                user.username != "Nicolas Arana"
            ).length
          }
        </h3>

        <table className="table table-hover">
          <thead>
            <tr className="admin-header">
              <th scope="col">#</th>
              <th scope="col">Teacher Name</th>
              <th scope="col">Class ID</th>
              <th scope="col">Number of Students</th>
            </tr>
          </thead>
          <tbody>
            {teachers.map(({ username, classroomId }, index) => {
              let numberOfStudents = users.filter(
                (user) =>
                  user.classroomId === classroomId && user.username !== username
              );
              console.log("Students with same ID", numberOfStudents);
              return (
                <tr key={index} className="admin-row">
                  <th scope="row">{index}</th>
                  <td>{username}</td>
                  <td>{classroomId}</td>
                  <td>{numberOfStudents.length}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  } else {
    return "Loading...";
  }
};

export default AdminTable;
