import React, { useEffect } from "react";
import "./index.css";

import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import InitialAssesment from "../../../InitialAssesment";

import { CheckOutlined } from "@ant-design/icons";
import BuiltWith from "./BuiltWith";
import Discount from "./Discount";
import Hero from "./Hero";
import Hero2 from "./Hero2";
import About1 from "./components/About1";
import Features1 from "./components/Features1";
import About2 from "./components/About2";
import Newsletter from "./Newsletter";

function Landing2(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToGames = () => {
    props.history.push("/games");
  };
  return (
    <div className="landing-container">
      {/* <LoginButton isLoggedIn={false} startAuth={props.startAuth} /> */}
      {/* <Discount /> */}

      {/* <section>
        <div className="container"> */}
      {/* <Hero2 start={goToGames} /> */}
      <Hero start={goToGames} />
      {/* </div>
      </section> */}
      <About2 />

      <Features1 />

      {/* Old Landing */}
      {/* <div className="intro-landing">
        <div className="intro-landing-text-container">
          <div className="intro-landing-text">
            <h1>Get Ready for Real Life Finance</h1>
            <p>
              Learn finance <strong>fast</strong> with our simulations, games
              and tutorials.
            </p>
            <Link to="/games" className="play-now">
              Start Now
            </Link>
          </div>
        </div>
        <div className="intro-landing-image-container">
          <img
            className="row-container-1-image"
            src={"/assets/images/wallet.png"}
            width={600}
            height={"auto"}
            alt="snowball wallet"
          />
        </div>
      </div>

      <div className="games-showoff-container">
        <div className="showoff-text">
          <div className="intro-landing-text">
            <h1>Real Simulations.</h1>
            <h1>Real Learning.</h1>
            <p>
              Learn with real data on our Budgeting, Investing and Debt
              Simulators.
            </p>
          </div>
        </div>
        <img
          className="showoff-image"
          src={"/assets/images/real-data.png"}
          alt="showoff image"
        />
      </div>

      <div className="showoff-sheets-container">
        <div className="showoff-sheets-text">
          <h1>Real Spreadsheets</h1>
          <p>Learn Fast. Apply Immediately.</p>
        </div>
        <img
          className="row-container-2-image-sheet"
          src={"/assets/images/real-spreadsheets.png"}
          width={"100%"}
          height={"auto"}
          alt="snowball image"
        />
      </div> */}
      <InitialAssesment goToGames={goToGames} />

      <div className="features-container container">
        <img
          className="img-fluid"
          src={"/assets/images/manage-2.png"}
          width={500}
          // height={"auto"}
          alt="snowball features image"
        />
        <div className="features-text-container col-12 col-lg-6 col-xl-5 ">
          <h1 className="mt-3 font-weight-normal">
            Take <strong>control</strong> of your Financial Education
          </h1>

          <div className="features-list-container">
            <div className="features-list">
              <p>
                <CheckOutlined
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                />
                Spreadsheet Management
              </p>
              <p>
                <CheckOutlined
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                />
                Budgeting
              </p>
              <p>
                <CheckOutlined
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                />
                Investing
              </p>
              <p>
                <CheckOutlined
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                />
                Debt
              </p>
              <p>
                <CheckOutlined
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                />
                More Coming Soon!
              </p>
            </div>
            <button
              onClick={() => props.history.push("/games")}
              style={{ backgroundColor: "rgb(28, 29, 31)", color: "white" }}
              className="btn shadow mr-1 start-now-button"
            >
              Start Now
            </button>
          </div>
        </div>
      </div>
      <BuiltWith />
      <Newsletter />
      <div className="landing-button-bottom">
        <h2>
          Join <strong>thousands</strong> who learned how to manage their money.
        </h2>
      </div>
    </div>
  );
}

export default compose(withRouter)(Landing2);
