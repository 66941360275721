import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  filterFieldPath,
  getCollection,
  getDocFromCollection,
  getSubCollection,
  updateSubCollectionDoc,
} from "../../api/Firestore";

import "./index.css";
import { NotStarted } from "./components/NotStarted";

import StudentTable from "./components/StudentTable";
import StudentDataHeader from "./components/StudentDataHeader";
import ClassroomSettings from "./components/ClassroomSettings";
import ProgressDashboard from "./components/ProgressDashboard";
import simulation from "../Simulators/Sheets/Data/simulation-months";
import { toast } from "react-toastify";

const ViewClassroom = ({ userData }) => {
  const { classroomId } = useParams();
  const [classData, setClassData] = useState({
    students: null,
    name: null,
    lessons: null,
    lastRefresh: Date.now(),
  });
  const [viewType, setViewType] = useState("student-table");
  const [refreshing, setRefreshing] = useState(false);

  const [error, setError] = useState(null);
  const [inactiveStudentsFilter, setInactiveStudentsFilter] = useState(false);
  const [activeStudentsFilter, setActiveStudentsFilter] = useState(false);
  const [sortedByLastFirst, setSortedByLastFirst] = useState(false);

  useEffect(() => {
    handleClassData();
    return () => console.log("unmounting...");
  }, []);

  const handleClassData = async () => {
    console.log("ViewClassroom 46 | getting classroom data");
    const lessonsObject = await getAndSetLessonsWithSections();
    const className = await getClassName();
    const studentData = await getClassData();

    setClassData({
      ...studentData,
      name: className,
      students: studentData,
      lessons: lessonsObject,
      lastRefresh: Date.now(),
    });
  };

  const refreshStudentData = async () => {
    setRefreshing(true);

    try {
      console.log("ViewClassroom 55 | refreshing student data");
      const studentData = await getClassData();
      console.log("ViewClassroom 60 | class data", studentData);
      const classDataCopy = { ...classData };

      classDataCopy.students = studentData;
      setClassData(classDataCopy);
      setRefreshing(false);
      console.log("ViewClassroom 64 | refreshed!");
    } catch (error) {
      console.log("ViewClassroom 66 | error ", error);
      setRefreshing(false);
    }
  };

  const getClassName = async () => {
    try {
      const request = await getDocFromCollection("classrooms", classroomId);
      return request.data.classroomName;
    } catch (error) {
      return null;
    }
  };

  const getAndSetLessonsWithSections = async () => {
    let lessonsObject = {};
    let sectionsObject = {};

    try {
      console.log("App.js 503 | requesting lessons and sections");

      let requestLessons;
      let requestSections;

      const lessonsInStore = localStorage.getItem("snowball-lessons");
      const sectionsInStore = localStorage.getItem("snowball-sections");
      if (sectionsInStore && lessonsInStore) {
        console.log("App.js 508 | sections in store");
        requestLessons = JSON.parse(lessonsInStore);
        requestSections = JSON.parse(sectionsInStore);
      } else {
        console.log("App.js 516 | sections not in store");
        requestLessons = await getCollection("lessons");
        requestSections = await getCollection("sections");
        localStorage.setItem(
          "snowball-lessons",
          JSON.stringify(requestLessons)
        );
        localStorage.setItem(
          "snowball-sections",
          JSON.stringify(requestSections)
        );
      }

      const lessonsAndSectionsLoaded =
        requestLessons.data.length > 0 && requestSections.data.length;

      if (lessonsAndSectionsLoaded) {
        requestLessons.data.forEach((lesson) => {
          lessonsObject[lesson.id] = {
            lessonData: lesson.data,
            sections: [],
          };
        });

        requestSections.data.forEach((section) => {
          lessonsObject[section.data.lessonId].sections.push({
            data: section.data,
            id: section.id,
          });
          sectionsObject[section.data.lessonId] = { data: section.data };
        });
      } else {
        console.log("ViewClassroom 114 | not setting lessons and sections");
      }

      // setLessons(lessonsObject);
      return lessonsObject;
    } catch (error) {
      return null;
    }
  };

  const getClassData = async () => {
    console.log("ViewClassroom 68 | class data", classData);
    // if (classData.students) return;
    try {
      const classroomRequest = await getSubCollection(
        "classrooms",
        classroomId,
        "users"
      );

      let students = [];
      if (classroomRequest) {
        classroomRequest.data.forEach((user) => {
          if (user.data.name) {
            students.push(user.data);
          }
        });

        console.log("ViewClassroom 85 | students should be sorted", students);
        return students;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const lastFirst = () => {
    setSortedByLastFirst(!sortedByLastFirst);
  };

  const sortName = () => {
    let classDataCopy = { ...classData };
    classDataCopy.students.sort((a, b) => {
      if (sortedByLastFirst) {
        console.log("ViewClassroom 110 | last first toggled, sort differently");
        let aLastName = a.name.split(" ").reverse();
        let bLastName = b.name.split(" ").reverse();
        if (aLastName < bLastName) {
          return -1;
        }
        if (aLastName > bLastName) {
          return 1;
        }
        return 0;
      } else {
        console.log("ViewClassroom 112 | normal sort");
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    });

    setClassData(classDataCopy);
  };

  const sortLastLogin = () => {
    let classDataCopy = { ...classData };

    classDataCopy.students.sort((a, b) => {
      return a.lastLogin < b.lastLogin ? 1 : -1;
    });

    console.log("ViewClassroom 91 | class data sorting", classDataCopy);
    setClassData(classDataCopy);
  };

  const handleUserNameUpdate = async (studentId, newName) => {
    console.log(
      "ViewClassroom 138 | update user and fetch class data",
      studentId,
      classroomId,
      newName
    );

    // update subcollection doc of class id with student id
    try {
      await updateSubCollectionDoc(
        "classrooms",
        classroomId,
        "users",
        studentId,
        { name: newName }
      );

      await refreshStudentData();
    } catch (error) {
      // toastify error
      toast.error("Error updating");
    }
  };

  return (
    <div className="classroom-container container">
      {error && <div>{error}</div>}

      <StudentDataHeader
        className={classData.name}
        classData={classData.students}
      />

      <div className="togglestudentviews__container">
        <SwitchViewType
          title="Student Activity"
          name="student-table"
          viewType={viewType}
          setViewType={setViewType}
        />
        <SwitchViewType
          title="Progress & Completions"
          name="student-progress"
          viewType={viewType}
          setViewType={setViewType}
        />
        <SwitchViewType
          title="Class Settings"
          name="classroom-settings"
          viewType={viewType}
          setViewType={setViewType}
        />
      </div>
      <div className="classdata__refresh">
        <button
          className="btn btn-sm btn-outline-info"
          onClick={() => {
            refreshStudentData();
          }}
        >
          {refreshing
            ? "Refreshing..."
            : `🔁 Refresh (last refresh) ${Date(classData.lastRefresh)
                .toString()
                .slice(4, 21)}`}
        </button>
      </div>

      {viewType === "student-table" && (
        <StudentTable
          lastFirst={lastFirst}
          sort={sortName}
          lastLogin={sortLastLogin}
          classData={classData.students}
          handleUserNameUpdate={handleUserNameUpdate}
          sortedByLastFirst={sortedByLastFirst}
          setSortedByLastFirst={setSortedByLastFirst}
          inactiveStudentsFilter={inactiveStudentsFilter}
          setInactiveStudentsFilter={setInactiveStudentsFilter}
          activeStudentsFilter={activeStudentsFilter}
          setActiveStudentsFilter={setActiveStudentsFilter}
        />
      )}
      {viewType === "student-progress" && (
        <div>
          <ProgressDashboard
            classData={classData.students}
            lessons={classData.lessons}
            sortedByLastFirst={sortedByLastFirst}
            setSortedByLastFirst={setSortedByLastFirst}
          />
          <NotStarted classData={classData.students} />
        </div>
      )}

      {viewType === "classroom-settings" && (
        <ClassroomSettings classroomId={classroomId} userData={userData} />
      )}
    </div>
  );
};

const SwitchViewType = ({ title, name, viewType, setViewType }) => {
  return (
    <button
      onClick={() => setViewType(name)}
      className={`btn ${
        viewType === name ? "btn-outline-primary font-weight-bold" : null
      }`}
    >
      {title}
    </button>
  );
};

export default ViewClassroom;
