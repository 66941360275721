import React from "react";
import "./components.css";

const Title = () => {
  return (
    <h3 className="pro-title">
      Join SNOWBALL <span className="pro-red">PRO</span>
    </h3>
  );
};

export default Title;
