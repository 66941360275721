import React from "react";

import "./index.css";

const QuizResults = ({ score, goToGames }) => {
  if (score > 0.7) {
    return (
      <div className="results-need-snowball">
        <h4>
          <span className="success-span">
            You passed with a score of {Math.floor(score * 100)} %
          </span>
        </h4>
        <p>Our simulations will be a good refresher!</p>
        <button onClick={() => goToGames()} className="results-button">
          Start Learning Now
        </button>
      </div>
    );
  } else {
    return (
      <div className="results-need-snowball">
        <h4>
          <span className="failed-span">
            You scored {Math.floor(score * 100)} %
          </span>
        </h4>
        <p>That's why you're here!</p>
        <button onClick={() => goToGames()} className="results-button">
          Start Learning Now
        </button>
      </div>
    );
  }
};

export default QuizResults;
