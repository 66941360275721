const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8080"
    : "https://server-snowball.herokuapp.com";

export const _post = async (route, body) => {
  console.log("helpers.js 6 | posting");
  try {
    const request = await fetch(`${API_URL}/${route}`, {
      method: "POST",
      body: (body && JSON.stringify(body)) || null,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await request.json();
    return data;
  } catch (error) {
    console.log("helpers.js 14 | error getting data", error);
    return error;
    // throw new Error('Request error', route, body);
  }
};

export const _postWithToken = async (route, body, token) => {
  try {
    const request = await fetch(route, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const data = await request.json();
    return data;
  } catch (error) {
    throw new Error("Error posting token", error);
  }
};
