const initialState = { currentLocation: "/" };

export const locationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "location/set":
      return { ...state, currentLocation: payload };

    default:
      return state;
  }
};
