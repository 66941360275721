import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Modal from "antd/es/modal";

import timePng from "../../components/ViewLesson/assets/Time.png";
import { updateCollectionDoc } from "../../../../api/Firestore";
import Input from "../../../shared/Input";

class Lesson extends Component {
  state = {
    editModalOpen: false,
    name: this.props.name,
    learningObjectives: this.props.learningObjectives,
    id: this.props.id,
    image: this.props.image,
    orderNumber: this.props.orderNumber,
    time: this.props.time,
  };

  toggleModal = () => {
    this.setState({ editModalOpen: !this.state.editModalOpen });
  };

  handleSubmit = () => {
    const { name, learningObjectives, id, orderNumber, image, time } =
      this.state;
    updateCollectionDoc("lessons", id, {
      name,
      learningObjectives,
      orderNumber: parseInt(orderNumber),
      image: image,
      time,
    })
      .then(() => {
        console.log("Lessons 35 | updated lessons!");
        this.props.getLessons();
        this.toggleModal();
      })
      .catch((error) => {
        console.log("Lessons 30 | ", "ERROR", error);
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { name, learningObjectives, id, orderNumber, image, time } =
      this.state;

    const { classSettings, user } = this.props;

    const available =
      classSettings && classSettings.lessons
        ? classSettings?.lessons[id]?.available
          ? true
          : false
        : true;

    return (
      <div className="lesson-card">
        <div className="lesson-card-title">
          <div className="lesson-card-title-content">
            <h2 className="lesson-card-title">{name}</h2>
            <p className="lesson-card-subtitle">{learningObjectives}</p>
            <span>
              <img src={timePng} width={15} /> {this.props.time}
            </span>
          </div>
          <div className="start-lesson-link">
            {available ? (
              <Link to={`lesson/${id}`}>START</Link>
            ) : (
              <div className="btn disabled btn-sm text-white p-0">
                Unavailable
              </div>
            )}
          </div>
        </div>
        <img
          className="card-img"
          src={image}
          placeholder={<div>Loading</div>}
          alt={image}
        />

        {user ? (
          user.isAdmin ? (
            <>
              <button onClick={this.toggleModal} to={`lesson/${id}`}>
                Edit
              </button>
            </>
          ) : null
        ) : null}

        <Modal
          visible={this.state.editModalOpen}
          onOk={this.handleSubmit}
          onCancel={this.toggleModal}
        >
          <Input
            name="name"
            value={name}
            onChange={(e) => this.handleChange(e)}
          />
          <Input
            name="learningObjectives"
            value={learningObjectives}
            onChange={(e) => this.handleChange(e)}
          />
          <Input
            name="orderNumber"
            value={orderNumber}
            onChange={(e) => this.handleChange(e)}
          />
          <Input
            name="image"
            value={image}
            onChange={(e) => this.handleChange(e)}
          />
          <Input
            name="time"
            value={time}
            onChange={(e) => this.handleChange(e)}
          />
          {/* <Input value={name} /> */}
        </Modal>
      </div>
    );
  }
}

export default Lesson;
