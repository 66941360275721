import React from "react";

export const NotStarted = ({ classData }) => {
  return (
    <div className="not-started-container">
      <h3>Not Started</h3>
      <div className="not-started-students">
        {classData &&
          classData.map((student, index) => {
            if (student.lessons) {
              // console.log("NotStarted.js 11 | has progress", student);
              return null;
            }
            return (
              <div className="not-started" key={index}>
                {student.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};
