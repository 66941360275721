import React, { useState } from "react";
import { questions } from "./questions";
import "./index.css";
import QuizResults from "./QuizResults";
import {
  addDocToCollection,
  getDocFromCollection,
  updateCollectionDoc,
} from "../../../api/Firestore";

let quizzesRoute =
  process.env.NODE_ENV === "development"
    ? "testLandingQuizResults"
    : "landingQuizResults";

const QuizQuestions = ({ finished, setFinished, goToGames }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [score, setScore] = useState(0);
  let currentQuestion = questions[questionIndex];
  const { question, options } = currentQuestion;

  const handleAnswer = (index, correct) => {
    if (questionIndex === questions.length - 1) {
      setFinished(true);
      submitToDatabase(score, answers);
    } else {
      let answersCopy = [...answers];
      console.log("QuizQuestions.js 19 | correct", correct);
      if (correct) {
        setScore(score + 1);
      }
      answersCopy.push(questions[index]);
      setAnswers(answersCopy);
      setQuestionIndex(questionIndex + 1);
    }
  };

  const submitToDatabase = async () => {
    const scoreToSubmit = Math.floor(score / (questions.length - 1));
    try {
      let doc = await getDocFromCollection(quizzesRoute, "results");
      const { averageScore, failed, passed, timesTaken } = await doc.data;
      await addDocToCollection(quizzesRoute, {
        score: scoreToSubmit,
        date: Date.now(),
      });
      await updateCollectionDoc(quizzesRoute, "results", {
        timesTaken: timesTaken + 1,
        passed: scoreToSubmit > 0.7 ? passed + 1 : passed,
        failed: scoreToSubmit < 0.7 ? failed + 1 : failed,
        averageScore: averageScore + scoreToSubmit,
      });
    } catch (error) {
      console.log("QuizQuestions.js 48 | error submitting scores data");
    }
  };
  if (!finished) {
    return (
      <div className="quiz-questions-container">
        <p className="quiz-question">{question}</p>
        <div className="options-box">
          {options &&
            options.map(({ option, correct }, index) => {
              return (
                <button
                  className="quiz-answer"
                  onClick={() => handleAnswer(index, correct)}
                  key={index}
                >
                  {option.toUpperCase()}
                </button>
              );
            })}
        </div>
      </div>
    );
  } else {
    return (
      <QuizResults
        score={score / (questions.length - 1)}
        goToGames={goToGames}
      />
    );
  }
};

export default QuizQuestions;
