import React from "react";
import "./Login.css";
import ChooseSimulation from "./ChooseSimulation/ChooseSimulation.js";
import Landing from "./Landing/Landing";

const Login = (props) => {
  if (props.token) {
    return (
      <ChooseSimulation
        logOut={props.logOut}
        user={props.user}
        paidUser={props.paidUser}
        startAuth={props.startAuth}
        classSettings={props.classSettings}
      />
    );
  } else {
    return <Landing startAuth={props.startAuth} />;
  }
};

export default Login;
