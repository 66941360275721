import React, { Component, lazy, Suspense } from "react";
import io from "socket.io-client";
import Login from "./components/Login/Login";
import Policy from "./components/Policy/Policy";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import "./App.css";
import "antd/dist/antd.css";

import LoadingModal from "./components/Simulators/Sheets/sheetsComponents/modals/LoadingModal";
import AdminView from "./components/AdminView";
import history from "./history";

import { _paidUserTrue, _paidUserFalse } from "./api/user";

import { Helmet } from "react-helmet";
// import ChooseSimulation from "./components/Login/ChooseSimulation/ChooseSimulation";
import CheckPaymentStatus from "./components/CheckPaymentStatus";
import { LoginModal } from "./components/LoginModal";

import Classrooms from "./components/Classrooms";
import ViewClassroom from "./components/ViewClassroom";

import ReactGA from "react-ga";
import {
  filterDocsFromCollection,
  updateSubCollectionDoc,
} from "./api/Firestore";
import Spreadsheets from "./components/Spreadsheets";
import UserHandler from "./components/UserHandler";

// Sentry Setup
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Sentry.init({
  dsn: "https://45d4170bcd864404b38dcdd8387d3880@o1024485.ingest.sentry.io/5990307",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

// import AboutUs from "./components/AboutUs";
const AboutUs = lazy(() => import("./components/AboutUs"));

//Game containers
const SheetsContainer = lazy(() =>
  import("./components/Simulators/Sheets/SheetsContainer/SheetsContainer")
);
const InvestingContainer = lazy(() =>
  import(
    "./components/Simulators/Investing/InvestingContainer/InvestingContainer"
  )
);
const DebtContainer = lazy(() =>
  import("./components/Simulators/Debt/DebtContainer/DebtContainer")
);

const ViewAndEditSection = lazy(() =>
  import("./components/LessonsContainer/components/Section/ViewAndEditSection")
);
const ViewLesson = lazy(() =>
  import("./components/LessonsContainer/components/ViewLesson")
);

const ChooseSimulation = lazy(() =>
  import("./components/Login/ChooseSimulation/ChooseSimulation")
);

const Profile = lazy(() => import("./components/Profile/Profile"));

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://server-snowball.herokuapp.com"
    : "http://127.0.0.1:8080";

// Pointer to api with socket.io
const socket = io(API_URL);

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      token: null,
      disabled: "",
      api: API_URL,
      userData: null,
      sheetsLevel: 0,
      studentData: null,
      isTeacher: false,
      paid: false,
      classroomId: null,
      debtData: null,
      tickers: [""],
      paidUser: false,
      lessons: null,
      // ['AAPL', 'MSFT', 'TQQQ', 'DIA','BAC', 'TWTR', 'EPU', 'FAS', 'SPY', 'TWOU', 'TLT', 'GME', 'SBUX', 'GLD', 'BABA', 'TSLA', 'FB', 'GOOGL', 'AMZN', 'GS', 'GE', 'F'], these tickers are already included
      databaseUpdated: true,
      // change this and the tickers to update database with more tickers!

      stocksData: [],
      stocksDataFromDb: [],
      stocksNum: 0,
      // classSettings: null,
      // classSettings: { sections: {} },
      classSettings: null,
    };
    this.popup = null;
  }

  // Gets lessons, sections and slides from the database
  componentWillMount() {
    this._getUserFromSessionOrGoogleSocket();
    if (!this.state.databaseUpdated) {
      let timer = setInterval(this.updateFlow, 12000);
    }
  }

  async componentDidMount() {
    console.log("App.js 131 | app mounted");
    ReactGA.initialize("UA-125543594-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  getStocksByTicker = async (ticker) => {
    const request = await fetch(
      `https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=${ticker}&apikey=R3XR`
    );
    const data = await request.json();
    return data;
  };

  getStocks = async () => {
    const { stocksNum, tickers } = this.state;

    let apiKey = "R3XR";
    const requests = await fetch(
      `https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY&symbol=${tickers[stocksNum]}&apikey=${apiKey}`
    );
    const data = await requests.json();
    return data;
  };

  updateFlow = async () => {
    const { stocksNum, tickers } = this.state;

    if (stocksNum < tickers.length) {
      this.getStocks().then((stock) => {
        if (stock["Monthly Time Series"]) {
          const entries = Object.entries(stock["Monthly Time Series"]);
          const prices = entries
            .map((entry) => {
              const price = entry[1]["4. close"];
              return parseFloat(price);
            })
            .reverse();
          const dates = entries
            .map((entry) => {
              const date = entry[0];
              return date;
            })
            .reverse();

          this.getStocksByTicker(stock["Meta Data"]["2. Symbol"]).then(
            (stockRetrieved) => {
              const stockObject = {
                name: stockRetrieved["bestMatches"][0]["2. name"],
                ticker: stock["Meta Data"]["2. Symbol"],
                prices: prices,
                dates: dates,
              };
              const newData = [...this.state.stocksData, stockObject];
              this.setState(
                {
                  stocksData: newData,
                  stocksNum: this.state.stocksNum + 1,
                },
                () => {
                  console.log("updated data", this.state.stocksData);
                }
              );
            }
          );
        } else {
          console.log("Not defined");
        }
      });
    } else {
      if (!this.state.databaseUpdated) {
        console.log("Submit to database");
        this._submitStocksToDatabase(this.state.stocksData);
        this.setState({
          databaseUpdated: true,
        });
      }
    }
  };

  _submitStocksToDatabase = async (stocksArray) => {
    // console.log(API_URL);
    console.log("user data", this.state.stocksData);
    // This updates the current level in the database if the level is different than 0
    const url = `${API_URL}/updateStocksData`;
    console.log("Url submitted", url);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stocksData: stocksArray, //Updates with the current state
      }),
    });
  };

  _updateLevel = async (level, averageSavings) => {
    this.setState(
      {
        levelNum: level,
      },
      () => {
        // This updates the current level in the database if the level is different than 0
        const url = `${API_URL}/updateSaveFile`;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            googleId: this.state.user.id,
            sheetsLevel: level, //Updates with the current state
            averageSavings: Math.floor(averageSavings),
          }),
        });
      }
    );
  };

  _getUserFromSessionOrGoogleSocket = () => {
    const sessionUser = sessionStorage.getItem("user");
    if (sessionUser) {
      const parsedSessionUser = JSON.parse(sessionUser);
      this._addUserToState(parsedSessionUser);
    } else {
      // console.log("App.js 232 | no user in session");
      this._connectToGoogleSocket(this._addUserToState);
    }
  };

  _updateClassId = (classId) => {
    this.setState({
      classroomId: classId,
    });
    // Here, I grab the value and post it to the user's profile and update class Id
  };

  _handleUpdateClassId = async (classId) => {
    // event.preventDefault();
    console.log("App.js 246 | handling class update");
    const request = await fetch(`${API_URL}/updateClassroomId`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        classroomId: classId,
        googleId: this.state.userData.googleId,
      }),
    });
    const user = await request.json();
    const userDataCopy = { ...this.state }.userData;
    userDataCopy.classroomId = user.updatedUser.classroomId;
    this.setState({ userData: userDataCopy });
    console.log("App.js 258 | user updated", user);
  };

  _addUserToState = async (user) => {
    let userData = await this.getUserDataById(user.id);
    console.log("App.js 269 | user", user);

    if (userData) {
      console.log("App.js 264 | user ID", userData._id);
      this.handleLastLogin(userData);
      this.setState({
        user: user,
        token: user.token,
        userData: userData,
        debtData: user.averageSavings ? user.averageSavings : 400,
        paid: user.paidUser ? user.paidUser : false,
        isTeacher: user.isTeacher ? user.isTeacher : false,
        isAdmin: user.isAdmin ? true : false,
      });
    } else {
      let userData = await this.getUserDataById(user.id);
      this.handleLastLogin(userData);
      this.setState({
        user: user,
        token: user.token,
        userData: userData,
        debtData: user.averageSavings ? user.averageSavings : 0,
        paid: user.paidUser ? user.paidUser : false,
        isTeacher: user.isTeacher ? user.isTeacher : false,
        isAdmin: user.isAdmin ? true : false,
      });
    }
  };

  handleLastLogin = async (userData) => {
    if (userData?.classroomId) {
      const classroomExists = await filterDocsFromCollection(
        "classrooms",
        "classroomName",
        "==",
        userData.classroomId
      );

      if (classroomExists.data.length) {
        const classId = classroomExists.data[0].id;
        let userDataCopy = { ...this.state };
        userDataCopy.userData.classUID = classId;
        this.setState(userDataCopy);
        const classSettings = classroomExists.data[0];

        this.handleClassSettings(classSettings.data);

        await updateSubCollectionDoc(
          "classrooms",
          classId,
          "users",
          userData._id,
          {
            lastLogin: Date.now(),
            email: userData.email,
          }
        );
      } else {
        console.log("container 254 | no class");
      }
    }
  };

  handleClassSettings = (classSettings) => {
    if (classSettings) {
      this.setState({ classSettings });
    }
  };

  getUserDataById = async (userId) => {
    try {
      const url = `${API_URL}/getSaveFile?googleId=${userId}`;
      const request = await fetch(url);
      const data = await request.json();
      // console.log("user data by Id with Id", data, userId);
      return data;
    } catch (error) {
      console.log("App.js 285 | error getting user Data");
    }
  };

  _connectToGoogleSocket = (_addTokenToState) => {
    socket.on("user", (user) => {
      console.log("App.js 288 | got user", user);
      this.popup.close();
      _addTokenToState(user);
      sessionStorage.setItem("user", JSON.stringify(user));
    });
  };

  checkPopup = () => {
    const check = setInterval(() => {
      const { popup } = this;
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(check);
        this.setState({ disabled: "" });
        history.push("/");
      }
    }, 1000);
  };

  redirectToProfile = () => {
    history.push("/profile");
  };

  openPopup = () => {
    const width = 800,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const url = `${API_URL}/google?socketId=${socket.id}`;

    return window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
  };

  startAuth = () => {
    console.log("App.js 329 | starting auth");
    if (!this.state.disabled) {
      this.popup = this.openPopup();
      this.checkPopup();
      this.setState({ disabled: "disabled" });
    }
  };

  logOut = () => {
    history.push("/");
    history.go(0);
    sessionStorage.clear();
    this.setState({
      user: null,
      token: null,
      userData: null,
      paid: false,
    });
  };

  _getUserData = async () => {
    try {
      const url = `${API_URL}/getSaveFile?googleId=${this.state.user.id}`;
      const request = await fetch(url);
      const data = await request.json();
      console.log("user data", data);
      return data;
    } catch (error) {
      console.log("App.js 353 | unable to get userData", error.message);
    }
  };

  _setPaidUser = async () => {
    return _paidUserTrue(this.state.user.id)
      .then(() => {
        console.log("App.js 336 | paid user true");
        this._getUserData()
          .then((user) => {
            this.setState({
              paidUser: user.paidUser,
            });
            console.log("App.js | 381 user data for paid", user);
          })
          .catch((error) => {
            console.log(" | 381 error updating paid user");
          });
        this.redirectToProfile();
      })
      .catch((error) => {
        console.log("App.js 336 | error setting paid user", "ERROR", error);
      });
  };

  _paidUserFalse = async () => {
    const url = `${API_URL}/updatePaidUser`;
    const testUrl = `http://localhost:8080/updatePaidUser`;
    return _paidUserFalse(url, this.state.user.id)
      .then(() => {
        console.log("App.js 336 | paid user true");
        this._getUserData()
          .then((user) => {
            console.log("App.js | 402");
            this.setState({ paidUser: user.paidUser });
          })
          .catch((error) => {
            console.log(" | 402");
          });
      })
      .catch((error) => {
        console.log("App.js 336 | error setting paid user", "ERROR", error);
      });
  };

  clearToken = () => {
    this.logOut();
  };

  render() {
    const { disabled } = this.state;
    return (
      <BrowserRouter history={history}>
        <Suspense
          fallback={
            <Navigation
              logOut={this.logOut}
              token={this.state.token}
              isTeacher={this.state.isTeacher}
              userData={this.state.userData}
              startAuth={this.startAuth}
            />
          }
        >
          <div className="app">
            <ToastContainer />
            <Helmet>
              <title>Snowball Financial Education</title>
              <meta
                name="description"
                content="Personal Finance Games with Real Spreadsheets."
              />
              <meta
                name="keywords"
                content="Get Ready for Real Life Finance, Gamified Financial Education, Financial Education Gamified, financial education games, financial literacy games, finance games, gamified finance, snowball, financial literacy, snowball financial, snowball finances, snowball financial education, Finance, Education, Money management games, Money management simulations"
              />
            </Helmet>

            <Navigation
              pathname={window.location.pathname}
              logOut={this.logOut}
              token={this.state.token}
              isTeacher={this.state.isTeacher}
              userData={this.state.userData}
              startAuth={this.startAuth}
            />

            <div className="content">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Login
                      {...props}
                      token={this.state.token}
                      disabled={disabled}
                      startAuth={this.startAuth}
                      user={this.state.userData}
                      paidUser={this.state.paidUser}
                      classSettings={this.state.classSettings}
                    />
                  )}
                />
                <Route
                  path="/games"
                  render={(props) => {
                    return (
                      <ChooseSimulation
                        {...props}
                        token={this.state.token}
                        startAuth={this.startAuth}
                        paidUser={this.state.paidUser}
                        API_URL={API_URL}
                        user={this.state.user}
                        classSettings={this.state.classSettings}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <Profile
                      {...props}
                      _handleUpdateClassId={this._handleUpdateClassId}
                      _updateClassId={this._updateClassId}
                      user={this.state.user}
                      isTeacher={this.state.isTeacher}
                      userData={this.state.userData}
                      _setPaidUser={this._setPaidUser}
                      _paidUserFalse={this._paidUserFalse}
                      _getUserData={this._getUserData}
                      paidUser={this.state.paidUser}
                      startAuth={this.startAuth}
                    />
                  )}
                />
                )
                <Route path="/policy" render={(props) => <Policy />} />
                <Route
                  path="/checkPaymentStatus"
                  render={(props) => (
                    <CheckPaymentStatus
                      _setPaidUser={this._setPaidUser}
                      redirectToProfile={this.redirectToProfile}
                      _getUserData={this._getUserData}
                    />
                  )}
                />
                
                <Route
                  path="/lesson/:lessonId"
                  component={(props) => {
                    if (this.state.token) {
                      return (
                        <ViewLesson
                          {...props}
                          paidUser={this.state.paidUser}
                          userData={this.state.userData}
                          classSettings={this.state.classSettings}
                        />
                      );
                    } else {
                      return <LoginModal startAuth={this.startAuth} />;
                    }
                  }}
                />
                <Route
                  path={`/section/:sectionId`}
                  render={(props) => {
                    return (
                      <ViewAndEditSection
                        {...props}
                        getUserData={this._getUserData}
                        token={this.state.token}
                        isAdmin={this.state.isAdmin}
                        userData={this.state.userData}
                        logOut={this.logOut}
                      />
                    );
                  }}
                />
                <Route
                  path="/spreadsheets"
                  component={(props) => {
                    if (this.state.token) {
                      return (
                        <Spreadsheets {...props} token={this.state.token} />
                      );
                    } else {
                      return <LoginModal startAuth={this.startAuth} />;
                    }
                  }}
                />
                <Route
                  path="/aboutUs"
                  render={(props) => {
                    return <AboutUs startAuth={this.startAuth} />;
                  }}
                />
                <Route
                  path="/debt"
                  render={(props) => {
                    if (this.state.token) {
                      return (
                        <DebtContainer
                          {...props}
                          token={this.state.token}
                          API_URL={API_URL}
                          debtData={this.state.debtData}
                        />
                      );
                    } else {
                      return <LoginModal startAuth={this.startAuth} />;
                    }
                  }}
                />
                <Route
                  path="/stocks"
                  render={(props) => {
                    if (this.state.token && this.state.userData) {
                      return (
                        <InvestingContainer
                          {...props}
                          token={this.state.token}
                          API_URL={API_URL}
                          userData={this.state.userData}
                          stocksDataFromDb={this.state.stocksDataFromDb}
                        />
                      );
                    } else if (this.state.token && !this.state.userData) {
                      return <LoadingModal />;
                    } else {
                      return <LoginModal startAuth={this.startAuth} />;
                    }
                  }}
                />
                <Route
                  path="/sheets"
                  render={(props) => {
                    if (this.state.token) {
                      return (
                        <SheetsContainer
                          {...props}
                          _getLevel={this._getUserData}
                          _updateLevel={this._updateLevel}
                          sheetsLevel={this.state.sheetsLevel}
                          userData={this.state.userData}
                          userLevel={this.state.user.sheetsLevel}
                          user={this.state.user}
                          token={this.state.token}
                          API_URL={API_URL}
                          logOut={this.logOut}
                        />
                      );
                    } else {
                      return <LoginModal startAuth={this.startAuth} />;
                    }
                  }}
                />
                <Route
                  path="/classrooms"
                  component={(props) => {
                    return (
                      <Classrooms
                        userData={this.state.userData}
                        user={this.state.user}
                        _addUserToState={this._addUserToState}
                        _getUserData={this._getUserData}
                        _updateClassId={this._updateClassId}
                        _handleUpdateClassId={this._handleUpdateClassId}
                        _setPaidUser={this._setPaidUser}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/classroom/:classroomId"
                  component={(props) => {
                    if (this.state.userData && this.state.token) {
                      return (
                        <ViewClassroom
                          userData={this.state.userData}
                          token={this.state.token}
                          lessons={this.state.lessons}
                          getAndSetLessonsWithSections={
                            this.getAndSetLessonsWithSections
                          }
                        />
                      );
                    } else {
                      return "Loading...";
                    }
                  }}
                />
                <Route
                  path="/admin"
                  render={(props) => {
                    if (this.state.token) {
                      return (
                        <AdminView
                          {...props}
                          isAdmin={this.state.isAdmin}
                          API_URL={API_URL}
                          userData={this.state.userData}
                        />
                      );
                    } else {
                      return (
                        <Login
                          {...props}
                          token={this.state.token}
                          disabled={disabled}
                          startAuth={this.startAuth}
                          logOut={this.logOut}
                          paidUser={this.state.paidUser}
                        />
                      );
                    }
                  }}
                />
                <Route path="/userHandler" component={UserHandler} />
              </Switch>
            </div>
          </div>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
