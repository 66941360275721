import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

export default () => (
  <footer className="footer-dark">
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-3 item">
          <h3 className="footer-text">Services</h3>
          <ul>
            <li>
              <Link to="/games">Games</Link>
            </li>
            <li></li>
          </ul>
        </div>
        <div className="col-sm-6 col-md-3 item">
          <h3 className="footer-text">Privacy Policy</h3>
          <ul>
            <li>
              <Link to="/policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="col-md-6 item text">
          <h3 className="footer-text">Snowball Financial Education LLC</h3>
          <p>
            Financial Education Games that teach you real finance fast and fun.
          </p>
        </div>
      </div>
      <p className="copyrights">Snowball Financial Education LLC 2021</p>
    </div>
  </footer>
);
