import React from "react";

const sameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const StudentDataHeader = ({ className, classData }) => {
  return (
    <div className="classroom-stats">
      <h5>Classroom ID: </h5>
      <h3>{className}</h3>
      <p>Student Count: {classData && classData.length}</p>
      {/* <p>
        Online Students:{" "}
        {classData && classData.filter((student) => student.lastLogin).length}
      </p> */}
    </div>
  );
};

export default StudentDataHeader;
