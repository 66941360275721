import React, { useEffect, useState } from "react";
import {
  getCollection,
  getDocFromCollection,
  updateCollectionDoc,
} from "../../../../api/Firestore";

import "./index.css";

const ClassroomSettings = ({ classroomId, userData }) => {
  console.log("ClassroomSettings 3 | classroomId", classroomId);

  const [settings, setSettings] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState("");

  useEffect(() => {
    getLessons();
    getClassData();
  }, []);

  const isCreator = () => {
    console.log(
      "ClassroomSettings 20 | settings creator",
      settings?.creator,
      userData?._id
    );
    return settings?.creator === userData?._id;
  };

  const getLessons = async () => {
    try {
      const lessons = await getCollection("lessons");
      if (lessons.data.length > 0) {
        let sortedLessons = lessons.data.sort((a, b) => {
          return a.data.orderNumber < b.data.orderNumber ? 1 : 0;
        });
        setLessons(sortedLessons);
        console.log("ClassroomSettings 19 | lessons sorted", sortedLessons);
      }
    } catch (error) {
      console.log("ClassroomSettings 25 | error getting messages", error);
    }
  };

  const getClassData = async () => {
    try {
      const classroom = await getDocFromCollection("classrooms", classroomId);
      setSettings(classroom.data);
    } catch (error) {
      console.log(
        "ClassroomSettings 17 | error getting classroom settings",
        error
      );
    }
  };

  const handleLessonToggle = async (lesson, checked) => {
    let settingsCopy = { ...settings };

    if (settingsCopy[lesson.id]) {
      settingsCopy.lessons[lesson.id].available = !checked;
    } else {
      settingsCopy = {
        ...settingsCopy,
        lessons: {
          ...settingsCopy.lessons,
          [lesson.id]: {
            available: !checked,
          },
        },
      };
    }

    setSettings(settingsCopy);

    await updateCollectionDoc("classrooms", classroomId, settingsCopy);
    getClassData();
    // get and update settings

    console.log("ClassroomSettings 53 | settings", settings.classroomName);

    // updateCollectionDoc("classrooms");
  };

  const addTeacher = async () => {
    console.log("ClassroomSettings 75 | adding teacher", teacherEmail);
    let settingsCopy = { ...settings };
    if (settingsCopy.teachers) {
      settingsCopy.teachers = [...settingsCopy.teachers, teacherEmail];
    } else {
      settingsCopy.teachers = [teacherEmail];
    }
    setSettings(settingsCopy);
    await updateCollectionDoc("classrooms", classroomId, settingsCopy);
    getClassData();
  };

  return (
    <div className="container">
      <h3>Settings</h3>
      <div>
        <p>Available Lessons</p>
        {lessons &&
          lessons.map((lesson, index) => {
            const checked =
              settings?.lessons && settings.lessons[lesson.id]?.available
                ? true
                : false;
            return (
              <div key={index}>
                {lesson.data.name}{" "}
                <input
                  type="checkbox"
                  onClick={(e) => {
                    handleLessonToggle(lesson, checked);
                  }}
                  checked={checked}
                />
              </div>
            );
          })}
      </div>
      {isCreator() ||
        (userData.isAdmin && (
          <>
            <div className="addteacher__container">
              <h3 className="mt-5">Additional Teachers</h3>
              {settings?.teachers &&
                settings.teachers.map((teacher, index) => {
                  console.log("ClassroomSettings | 105", teacher);
                  return (
                    <div className="teacheremails__container" key={index}>
                      {teacher}
                    </div>
                  );
                })}
            </div>
            <label className="text-bold m-0 p-0 mt-4">Add Teacher</label>
            <input
              className="form-control"
              type="email"
              placeholder="Teacher Email..."
              value={teacherEmail}
              onChange={(e) => setTeacherEmail(e.target.value)}
            />
            <button onClick={addTeacher}>Add</button>
          </>
        ))}
    </div>
  );
};

export default ClassroomSettings;
