import React, { Component } from "react";
import Lesson from "../Lesson";

export default class LessonsViewPage extends Component {
  render() {
    const { lessons, getLessons, user, userProgress, classSettings } =
      this.props;

    if (lessons) {
      return (
        <div>
          {lessons.map(({ data, id }, index) => {
            return (
              <Lesson
                key={index}
                name={data.name}
                user={user}
                image={data.image}
                time={data.time}
                learningObjectives={data.learningObjectives}
                id={id}
                getLessons={getLessons}
                orderNumber={data.orderNumber}
                classSettings={classSettings}
                userProgress={userProgress}
              />
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}
