import React, { memo, useEffect } from "react";
import "./index.css";

const LessonWithSectionsProgressContainer = ({
  lesson,
  classData,
  sortedByLastFirst,
}) => {
  const [lessonId, lessonData] = lesson;
  useEffect(() => {}, []);

  if (!lesson) {
    return null;
  }

  return (
    <div className="lessonprogresslesson__container">
      <h3>{lessonData.lessonData.name}</h3>
      <div className="sectioncolumn__container">
        <div className="lessonusers__container">
          {classData &&
            classData.map((student, index) => {
              if (student) {
                const correctName = !sortedByLastFirst
                  ? student.name
                  : student?.name?.split(" ").reverse().join(", ");

                return (
                  <div
                    key={index}
                    onClick={() =>
                      console.log(
                        "LessonWithSectionsProgressContainer.js 21 | user name",
                        student
                      )
                    }
                    className="username__infocontainer"
                  >
                    <span>{correctName}</span>{" "}
                  </div>
                );
              } else {
                return (
                  <div key={index} className="username__infocontainer">
                    <span>No Name</span>{" "}
                  </div>
                );
              }
            })}
        </div>
        {lessonData.sections &&
          lessonData.sections
            .sort((a, b) => {
              return +a.data.orderNumber > +b.data.orderNumber ? 1 : -1;
            })
            .map((section, index) => {
              let sectionId = section.id;

              let usersThatCompletedSection = calculateTotalComplete(
                classData,
                sectionId
              );
              return (
                <div className="lessonSections__container" key={index}>
                  <div className="lessonSection__name">
                    <span>{section.data.sectionName}</span>
                    <span className="lessonsection__completecount">
                      Complete: {usersThatCompletedSection}
                    </span>
                  </div>
                  <div className="usersectionsdata__container">
                    {classData &&
                      classData.map((user, index) => {
                        return (
                          <UserSectionProgress
                            key={user.userId}
                            user={user}
                            section={section}
                          />
                        );
                      })}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

const UserSectionProgress = ({ user, section }, userSectionIndex) => {
  let sectionId = section.id;

  if (user.lessons && user.lessons[sectionId]) {
    const { levelNum, lessonLength, lessonName } = user.lessons[sectionId];

    let sectionProgress = 0;

    if (lessonLength && levelNum) {
      sectionProgress = Math.floor(((levelNum + 1) / lessonLength) * 100);
    } else if (levelNum) {
      if (lessonName === "Budgeting Simulator") {
        if (levelNum >= 3) {
          sectionProgress = 100;
        } else {
          sectionProgress =
            levelNum / 3 >= 1 ? 1 : Math.floor((levelNum / 3) * 100);
        }
      }
    } else {
    }

    return (
      <div
        key={userSectionIndex}
        className={`userprogress__box ${
          sectionProgress > 99 ? "usersection__complete" : null
        }`}
      >
        <span>{`${sectionProgress} % `}</span>
      </div>
    );
  } else {
    return (
      <div key={userSectionIndex} className="userprogress__box">
        <span>Not Started</span>
      </div>
    );
  }
};

const calculateTotalComplete = (classData, sectionId) => {
  let totalComplete = 0;
  classData &&
    classData.forEach((student) => {
      const lessonExists =
        student.lessons &&
        student.lessons[sectionId] &&
        student.lessons[sectionId].lessonLength - 1 ===
          student.lessons[sectionId].levelNum;
      const lessonHasSimulator =
        student.lessons &&
        student.lessons[sectionId] &&
        student.lessons[sectionId].lessonName === "Budgeting Simulator";

      if (lessonExists && !lessonHasSimulator) {
        totalComplete++;
      } else if (lessonHasSimulator) {
        if (student.lessons[sectionId].levelNum > 3) {
          totalComplete++;
        }
      } else {
        return;
      }
    });
  return totalComplete;
};

const generateName = (username, style = "normal") => {
  if (username) {
    if (style === "normal") {
      return username;
    } else {
      const splitName = username.split(" ");

      if (username.length === 2) {
        return `${splitName[1]}, ${splitName[0]}`;
      }

      if (splitName.length > 2) {
        // more than 2 word name

        return `${splitName[splitName[splitName.length - 1]]}, ${
          splitName[splitName.length - 1]
        }`;
      }
    }
  } else {
    return `No name`;
  }
};

export default memo(LessonWithSectionsProgressContainer);
