import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

export const CheckPaymentStatus = ({
  _setPaidUser,
  _getUserData,
  redirectToProfile,
  history,
}) => {
  useEffect(() => {
    console.log("CheckPaymentStatus 4 | approve payment status");
    _setPaidUser()
      .then(() => {
        console.log(
          "CheckPaymentStatus 5 | set paid user! Redirecting to profile"
        );
        _getUserData()
          .then(() => {
            console.log("CheckPaymentStatus 16 | got user data, redirecting");
            history.push("/profile");
          })
          .catch((error) => {
            history.push("/profile");
            console.log("CheckPaymentStatus 19 | ", "ERROR", error);
          });
      })
      .catch((error) => {
        console.log("CheckPaymentStatus 7 | ", "ERROR", error);
        redirectToProfile();
      });
  }, []);

  return <div>Creating Pro Membership...</div>;
};

export default withRouter(CheckPaymentStatus);
