// Current Stats

// Food: 34
// Transportation: 375
// Fun: 53
// Insurance: 0

const simulation = [
  {
    month: "January",
    events: [],
  },
  {
    month: "February",
    events: [],
  },
  {
    month: "March",
    events: [
      {
        situation: "You need a haircut for your date tonight",
        type: "Expense",
        category: {
          name: "Fun",
        },
        choices: [
          {
            action: "Let a student practice on you",
            happiness: -1,
            amount: 15,
          },
          {
            action: "See your favorite stylist",
            happiness: 2,
            amount: 50,
          },
        ],
      },
      {
        situation: "Your friends are going to Disney!",
        type: "Expense",
        category: {
          name: "Fun",
        },
        choices: [
          {
            action: "GO!",
            happiness: 3,
            amount: 100,
          },
          {
            action: "Skip",
            happiness: 0,
            amount: 0,
          },
        ],
      },
    ],
  },
];

export default simulation;
