import React, { useState, useEffect } from "react";
import "./index.css";

export const ModalBackground = (props) => {
  const [showModal, setShowModal] = useState(props.show);
  useEffect(() => {
    setShowModal(true);
  }, []);
  if (showModal) {
    return (
      <div className="modal-background">
        {props.children}
        <button
          className="modal-close-button"
          onClick={() => setShowModal(false)}
        >
          Close
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default ModalBackground;
