import {
  LOAD_LESSONS,
  LOAD_LESSONS_FAIL,
  LOAD_LESSONS_SUCCESS,
} from "./lessonsConstants";

// redux reducer
const initialLessonsState = {
  lessons: [],
  loading: false,
  error: null,
  selectedLesson: null,
  selectedLessonLoading: false,
  selectedLessonError: null,
  selectedLessonId: null,
  selectedLessonTitle: null,
  selectedLessonDescription: null,
  selectedLessonVideoUrl: null,
};

// redux reducer
export default function lessonsReducer(state = initialLessonsState, action) {
  // swith on action type
  switch (action.type) {
    // case for loading lessons
    case LOAD_LESSONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case for loading lessons success
    case LOAD_LESSONS_SUCCESS:
      return {
        ...state,
        loading: false,
        lessons: action.payload,
      };
    // case for loading lessons failure
    case LOAD_LESSONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
