import React from "react";
import "./index.css";
import logo from "./logo.png";

const NavigationHeader = () => {
  return (
    <div className="navigationheader__container">
      <div>
        <img src={logo} alt="" width={40} height={40} />
      </div>
      <div className="navigationheader__text">
        <div>Snowball</div>
        <div className="navigationheaded__subtext">Financial Education</div>
      </div>
    </div>
  );
};

export default NavigationHeader;
