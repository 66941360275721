import React, { Component } from "react";

import { compare } from "../../helpers";
import "./index.css";

import LessonsViewPage from "./components/LessonsViewPage";
import { getCollection, getDocFromCollection } from "../../api/Firestore";

class LessonsContainer extends Component {
  state = {
    lessons: null,
    userProgress: null,
    classSettings: null,
  };

  async componentDidMount() {
    this.getLessons();
    this.getUserProgress();

    console.log("LessonsContainer 16 | user", this.props.user);
  }

  getLessons = async () => {
    try {
      const dbSnapshot = await getCollection("lessons");
      this.setState({ lessons: dbSnapshot.data.sort(compare) });
    } catch (error) {
      console.log("LessonsContainer 37 | error getting lessons", error);
    }
  };

  getUserProgress = async () => {
    console.log("LessonsContainer 28 | getting user progress", this.props.user);
    if (this.props.user) {
      const userProgress = await getDocFromCollection(
        "users",
        this.props.user._id
      );
      console.log("LessonsContainer 32 | user progress", userProgress);
      this.setState({ userProgress });
    }
  };

  render() {
    const { lessons } = this.state;
    return (
      <div className="tutorials-container"> 
        {lessons ? (
          <LessonsViewPage
            lessons={lessons}
            user={this.props.user}
            getLessons={this.getLessons}
            userProgress={this.state.userProgress}
            classSettings={this.props.classSettings}
          />
        ) : (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
    );
  }
}

export default LessonsContainer;
