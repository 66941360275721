import React, { useState } from "react";
import { FileExcelFilled, FileExcelOutlined } from "@ant-design/icons";
import "./index.css";

const Spreadsheets = ({ token }) => {
  const [sheetFiles, setSheetFiles] = useState(null);

  const getSheetsFiles = async (token) => {
    console.log("Does Sheet Exist?");
    const drive = `https://www.googleapis.com/drive/v3/files`;
    console.log("Spreadsheets 7 | token", token);

    const request = await fetch(drive, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await request.json();
    return data.files;
  };

  const handleSheetFiles = async () => {
    const sheetFiles = await getSheetsFiles(token);
    console.log("Account 47 | sheetFiles", sheetFiles);
    setSheetFiles(sheetFiles);
  };
  React.useEffect(() => {
    handleSheetFiles();
  }, []);

  return <SheetFiles sheetFiles={sheetFiles} />;
};

const SheetFiles = ({ sheetFiles }) => {
  return (
    <div className="finance-sheets-container">
      <h3 className="finance-sheets-title">
        <FileExcelFilled style={{ marginRight: 10 }} /> Snowball Created
        Spreadsheets
      </h3>
      <div className="finance-sheets">
        {sheetFiles &&
          sheetFiles.map((file, index) => {
            return (
              <div className="finance__sheet">
                <FileExcelOutlined style={{ paddingRight: 5 }} />
                <a
                  target="_blank"
                  rel="noopener noreferer"
                  href={`https://docs.google.com/spreadsheets/d/${file.id}`}
                  key={index}
                >
                  {file.name}
                </a>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Spreadsheets;
