import React from "react";
import { CheckOutlined } from "@ant-design/icons";

import "./components.css";

const benefits = [
  "Early Access Price!",
  "One time payment, LIFETIME access.",
  "Help Build the Games!",
  "Investing Simulator",
  "Debt Simulator",
  "Every Future Game!",
  "Lifetime Updates",
  "Slack Community",
  "Talk with the Founder",
  "Everything in Free",
];

const Details = () => {
  return (
    <div>
      {benefits.map((benefit, index) => {
        return (
          <p className="detail" key={index}>
            <CheckOutlined style={{ paddingRight: 10, color: "white" }} />
            {benefit}
          </p>
        );
      })}
    </div>
  );
};

export default Details;
