import { combineReducers, createStore } from "redux";
import lessonsReducer from "./lessons/lessonsReducer";
import { locationReducer } from "./location/locationReducer";

const initialState = {
  lessons: [],
  // loaded: false,
};

// combine reducers

let reducers = combineReducers({
  lessons: lessonsReducer,
  location: locationReducer,
});

const store = createStore(
  reducers,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
